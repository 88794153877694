
export const capitalizeFirstLetter = string => {
    if (string) {
        let str = string.replace('_', ' ').split(' ');
        let arr = [];
        str.map(val => arr.push(val.charAt(0).toUpperCase() + val.slice(1).toLowerCase()))
        return arr.join(' ');
    } else {
        return string
    }
}
export const onlycapitalizeFirstLetter = string => {
    if (string) {
        let str = string.replace('_', ' ');
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    } else {
        return string
    }
}
