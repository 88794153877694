import gql from 'graphql-tag';
// const AllMESSAGECATALOG = (MessageTypeId) => gql`query($Veiw:Int,$Offset:Int,$searchStr:String) {
//     allMessageCatalogs (${MessageTypeId ? ',condition: {messageTypeId:"' + MessageTypeId + '"}' : ''},filter: {messageText: {likeInsensitive:$searchStr}},first: $Veiw,offset:$Offset) {
//       totalCount
//       nodes {
//         isActive
//         createdAt
//         createdBy
//         updatedAt
//         messageIdentifier
//         messageText
//         messageTypeId
//         messageCatalogId
//         messageTypeByMessageTypeId {
//           messageType
//         }
//         userProfileByCreatedBy {
//           firstName
//         }
//       }
//     }
//   }
// `
const AllMESSAGECATALOG = (MessageTypeId) => gql`query($Veiw:Int,$Offset:Int,$searchStr:String) {
  allMessageCatalogs (filter: {messageText: {likeInsensitive:$searchStr}},first: $Veiw,offset:$Offset) {
    totalCount
    nodes {
      isActive
      createdAt
      createdBy
      updatedAt
      messageIdentifier
      messageText
      messageTypeId
      messageCatalogId
      messageTypeByMessageTypeId {
        messageType
      }
      userProfileByCreatedBy {
        firstName
      }
    }
  }
}
`
const EDITLOG = gql`query($Id: UUID) {
  allMessageCatalogs(condition: {messageCatalogId: $Id}) {
    nodes {
      isActive
      messageIdentifier
      messageText
      messageTypeId
      messageCatalogId
      messageTypeByMessageTypeId {
        messageType
      }
    }
  }
}
`
const MESSAGETYPE = gql`query {
  allMessageTypes {
      nodes {
        messageType
        messageTypeId
      }
  }
}
`
const MESSAGEIDENTIFIER = gql`query {
  __typename
  allMessageCatalogs {
    nodes {
      messageIdentifier
    }
  }
}`
const ALERTQUEUE = gql`
query MyQuery {
  __typename
  allAlertQueues {
    nodes {
      alertQueueId
      senderId
      recipientId
      alertRuleId
    }
  }
}
`
const ALERTRULE = gql`
query MyQuery($Veiw:Int!,$Offset:Int!) {
  allAlertRules(first:$Veiw, offset: $Offset) {
    totalCount
    nodes {
      alertRuleCode
      createdAt
      emailSubject
      pushTitle
      smsBody
      isActive
      description
      alertRuleId
    }
  }
}
`;
const EDITALERTRULEID = gql`
query EditAlertRule($alertRuleId: UUID!) {
  alertRuleByAlertRuleId(alertRuleId: $alertRuleId) {
    alertRuleCode
    description
    emailBody
    emailSubject
    isActive
    isEmail
    isPush
    isSms
    pushBody
    pushTitle
    smsBody
  }
}
`;
export {
  AllMESSAGECATALOG,
  EDITLOG,
  MESSAGETYPE,
  MESSAGEIDENTIFIER,
  ALERTQUEUE,
  ALERTRULE,
  EDITALERTRULEID
}