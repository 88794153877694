import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Routes from "./Router";
import MessageCatalog from "../Components/MessageCatalog/Index";
import AlertRuleTable from "../Components/AlertRule/Index";
import Login from "../Components/Login";
import Signup from "../Components/signup";
import ApiPage from "../Components/ApiPage";

export const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        <Redirect exact path="/" to={Routes.Login} />
        <Route exact component={Login} path={Routes.Login} />
        <Route exact component={Signup} path={Routes.Signup} />
        <PrivateRoute
          exact
          component={MessageCatalog}
          path={Routes.MessageCatalog}
        />
        <PrivateRoute
          exact
          component={AlertRuleTable}
          path={Routes.AlertRule}
        />
        <PrivateRoute exact component={ApiPage} path={Routes.ApiPage} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
