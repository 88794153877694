export const Routes = {
    MessageCatalog: '/messagecatalog',
    NewCatalog: '/messagecatalog/:name',
    Editcatalog: '/messagecatalog/:name',
    AlertRule:   '/alertrule',
    Login: '/login',
    Signup: '/signup'
}

export default Routes;
