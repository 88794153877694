import React from 'react';
import { Grid, Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Menu from '../Common/Menu'
import SearchMessage from './SearchMessage'
import MessageTable from './MessageTable'
import Messagemodel from '../Common/MessageModel/MsgcatalogEdit'
import { ApolloConsumer } from 'react-apollo';

const styles = theme => ({
    root: {
        padding: "16px",
        borderRight: "1px solid " + theme.palette.background.border,
        width: "100%", minHeight: "81vh",
        backgroundColor: theme.palette.background.paperelevation

    },
})

class MessageCatalog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            HelpMenu: "Projects",
            menuIndex: 0,
            searchString: "",
            veiwCount: 10,
            openModel: false,
            callFrom: '',
            catalogId: "",
            searchStr: ""
        }
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    resize() {
        this.setState({ width: window.innerWidth });
    }
    handleChangeView = (count) => {
        this.setState({ veiwCount: count });
    }
    handleOpenModel = (name, id) => {
        this.setState((state) => ({
            openModel: true,
            callFrom: name,
            catalogId: id
        }));

    }
    handleCloseModel = () => {
        this.setState((state) => ({
            openModel: false
        }));

    }
    handleChangeprimary = (e) => {
        this.setState({ searchStr: e.target.value })
    }



    render() {
        const { width } = this.state;
        return (
            <ApolloConsumer>
                {
                    query => <Grid container >
                        <SearchMessage  handleChangeprimary={this.handleChangeprimary} searchStr={this.state.searchStr} handleOpenModel={this.handleOpenModel} handleCloseModel={this.handleCloseModel}/>
                        <Grid item style={{ display: 'flex', minHeight: '100vh', width: "100%", paddingTop: "58px" }} className={"mainContainerSet"}>
                            {width > 959 && <Menu />}
                            <Grid item container style={{ color: "#000", flex: '1', display: "unset", width: "100%" }}>
                                <Grid container>

                                    <Grid item style={{ width: "100%" }}>
                                        <MessageTable query={query} searchStr={this.state.searchStr} handleOpenModel={this.handleOpenModel} veiwCount={this.state.veiwCount} handleChangeView={this.handleChangeView} handleCloseModel={this.handleCloseModel} />
                                    </Grid>
                                </Grid>
                                <Dialog
                                    open={this.state.openModel}
                                    onClose={this.handleCloseModel}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <Messagemodel query={query} handleCloseModel={this.handleCloseModel} callFrom={this.state.callFrom} catalogId={this.state.catalogId} />
                                </Dialog>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </ApolloConsumer>
        )
    }
}
export default (withStyles(styles, { withTheme: true })((MessageCatalog)))
