import React from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Menu from "../Common/Menu";
import SearchMessage from "../MessageCatalog/SearchMessage";
import AlertRuleTable from "./AlertRuleTable";
import AlertRuleEdit from "../Common/AlertModel/AlertRuleEdit";
import SearchAlertRuleTop from "./SearchAlertRule";

import NetworkCall from "../../NetworkCall";

const styles = (theme) => ({
  root: {
    padding: "16px",
    borderRight: "1px solid " + theme.palette.background.border,
    width: "100%",
    minHeight: "81vh",
    backgroundColor: theme.palette.background.paperelevation,
  },
});

class AlertRule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert_data: [],
      HelpMenu: "Projects",
      menuIndex: 0,
      searchString: "",
      veiwCount: 10,
      openModel: false,
      callFrom: "",
      alert_rule_code: "",
      searchStr: "",
      loading: false,
    };
  }

  load_data = ({ offset, limit }) => {
    this.setState({ ...this.state, loading: true });
    var { searchStr } = this.state;
    NetworkCall(
      "/rules/get",
      "post",
      {
        profileId: window.sessionStorage.getItem("profileId"),
        offset,
        limit,
        searchStr: searchStr.length > 0 ? searchStr : null,
      },
      null,
      true
    )
      .then((response) => {
        let state = this.state;
        state.alert_data = response.data?.data;
        state.loading = false;
        console.log("now loading", this.state.loading);
        setTimeout(() => {
          this.setState({ ...state });
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ ...this.state, loading: true });
      });
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.load_data({ offset: 0, limit: 10 });
  }

  resize() {
    this.setState({ width: window.innerWidth });
  }
  handleChangeView = (count) => {
    this.setState({ veiwCount: count });
  };
  handleOpenModel = (name, id) => {
    this.setState({
      ...this.state,
      openModel: true,
      callFrom: name,
      alert_rule_code: id,
    });
  };
  handleCloseModel = () => {
    this.setState({
      ...this.state,
      callFrom: null,
      openModel: false,
    });
  };

  handleChangeSearch = (e) => {
    this.setState({ ...this.state, searchStr: e.target.value }, () => {
      this.load_data({ offset: 0, limit: 10 });
    });
  };

  render() {
    const { width } = this.state;
    return (
      <Grid container style={{ position: "fixed" }}>
        <SearchMessage
          handleChangeprimary={this.handleChangeSeacrh}
          searchStr={this.state.searchStr}
          handleOpenModel={this.handleOpenModel}
        />
        <Grid
          item
          style={{
            display: "flex",
            minHeight: "100vh",
            width: "100%",
            paddingTop: "12px",
          }}
          className={"mainContainerSet"}
        >
          {width > 959 && <Menu />}
          <Grid
            item
            container
            className="main"
            style={{
              color: "#000",
              flex: "1",
              display: "unset",
              width: "100%",
            }}
          >
            <SearchAlertRuleTop
              searchStr={this.state.searchStr}
              handleOpenModel={this.handleOpenModel}
              handleChangeSearch={this.handleChangeSearch}
            />

            {/* {this.state.loading ? <Backdrop open={true} style={{zIndex:"10000"}}><CircularProgress /></Backdrop> : null} */}
            <Grid container>
              <Grid item style={{ width: "100%", paddingTop: "4.4%" }}>
                <AlertRuleTable
                  loading={this.state.loading}
                  alert_data={this.state.alert_data}
                  searchStr={this.state.searchStr}
                  handleOpenModel={this.handleOpenModel}
                  veiwCount={this.state.veiwCount}
                  handleChangeView={this.handleChangeView}
                  load_data={this.load_data}
                />
              </Grid>
            </Grid>

            <AlertRuleEdit
              open={this.state.openModel}
              handleCloseModel={this.handleCloseModel}
              callFrom={this.state.callFrom}
              alert_rule_code={this.state.alert_rule_code}
              handleRefresh={this.handleRefresh}
              load_data={this.load_data}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles, { withTheme: true })(AlertRule);
