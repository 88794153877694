import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  DialogContent,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  Toolbar,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import NetworkCall from "../../NetworkCall";
import { AlertContext } from "../../Contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  fieldsBody: {
    background: "#f3f3f3",
    // margin: "10px 15px 17px 8px",
    marginBottom: "10px",
    marginTop: "10px",
    borderRadius: "10px",
    padding: "10px 15px 10px 15px",
  },
  TextfieldIndex: {
    fontSize: "0.65rem",
    color: "#636869",
  },
  buttonAlign: {
    width: "100%",
    textTransform: "none",
  },
}));

function ProfileModal(props) {
  var [state, setState] = React.useState({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
  });

  var toast = React.useContext(AlertContext);

  React.useEffect(() => {
    NetworkCall("/users/get", "post", {}, null, true)
      .then((response) => setState(response.data))
      .catch((err) => console.log(err));
  }, []);

  var handleChanges = (name) => (event) => {
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  var updateProfile = () => {
    NetworkCall("/users/update", "post", { ...state }, null, true)
      .then((response) => {
        toast.setSnack({
          open: true,
          severity: "success",
          msg: response.data.message,
        });
        props.handleProfileClose();
      })
      .catch((err) => {
        //console.log(err)
        toast.setSnack({
          open: true,
          severity: "error",
          msg: "Some error occured, Please try again!",
        });
      });
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Dialog maxWidth="xs" open={props.openProfile}>
        <DialogTitle style={{ padding: "4px 24px" }}>
          <Toolbar disableGutters style={{ padding: "0" }}>
            <Typography variant="h5">Profile</Typography>
            <div className={classes.grow} />
            <IconButton onClick={() => props.handleProfileClose()}>
              {" "}
              <CloseIcon fontSize="small"></CloseIcon>
            </IconButton>
          </Toolbar>
        </DialogTitle>
        <Divider></Divider>
        <DialogContent>
          <Grid container spacing={2} className={classes.fieldsBody}>
            <Grid item md={12} style={{ marginTop: 10 }}>
              <Typography className={classes.TextfieldIndex}>
                FIRST NAME*
              </Typography>
              <TextField
                id="firstName"
                variant="outlined"
                fullWidth={true}
                value={state.firstName}
                required
                size="small"
                placeholder="firstname"
                name="firstName"
                onChange={handleChanges("firstName")}
                margin="dense"
              />
            </Grid>
            <Grid item md={12} style={{ marginTop: 10 }}>
              <Typography className={classes.TextfieldIndex}>
                LAST NAME*
              </Typography>
              <TextField
                id="lastname"
                variant="outlined"
                fullWidth={true}
                value={state.lastName}
                required
                size="small"
                placeholder="lastname"
                name="lastname"
                onChange={handleChanges("lastName")}
                margin="dense"
              />
            </Grid>
            <Grid item md={12} style={{ marginTop: 10 }}>
              <Typography className={classes.TextfieldIndex}>
                MOBILE NUMBER*
              </Typography>
              <TextField
                id="mobile"
                variant="outlined"
                fullWidth={true}
                value={state.mobile}
                required
                size="small"
                placeholder="mobile"
                name="mobile"
                onChange={handleChanges("mobile")}
                margin="dense"
              />
            </Grid>
            <Grid item md={12} style={{ marginTop: 10 }}>
              <Typography className={classes.TextfieldIndex}>EMAIL*</Typography>
              <TextField
                id="email"
                variant="outlined"
                fullWidth={true}
                value={state.email}
                required
                size="small"
                placeholder="email"
                name="email"
                onChange={(e) => handleChanges("email")}
                margin="dense"
              />
            </Grid>

            <Grid
              container
              spacing={2}
              style={{
                marginBottom: "3%",
                marginTop: "5%",
                padding: "0px 8px 0px 8px",
              }}
            >
              <Grid item xs={6} align="center">
                <Button
                  className={classes.buttonAlign}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    updateProfile();
                  }}
                >
                  Update
                </Button>
              </Grid>
              <Grid item xs={6} align="center">
                <Button
                  className={classes.buttonAlign}
                  variant="contained"
                  onClick={() => props.handleProfileClose()}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ProfileModal;
