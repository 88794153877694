import React from "react";
import {
  makeStyles,
  Typography,
  Divider,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
// import axios from "axios";
import { green, red } from "@material-ui/core/colors";
import NetworkCall from "../../NetworkCall";

function createData(name, type, description) {
  return { name, type, description };
}

const rows = [
  createData("reference_id", "string", "Unique Representation of a alert"),
  createData("push_receivers", "array", "List of push notification receivers"),
  createData("push_title", "array", "List of data parameters for push title"),
  createData("push_body", "object", "List of data parameters for push body"),
  createData(
    "push_click_action",
    "string",
    "URL to open site when clicking a received push notification"
  ),
  createData(
    "push_icon",
    "string",
    "Pass an image URL to set as an icon to the push notification"
  ),
  createData("push_image", "string", "Pass an image URL to display the image in the push notification"),
  createData("push_actions", "array", "List of actions to display the action button"),
  createData("push_receiver_clientIds", "array of string", "List of push notification receivers's Id"),
  createData("inapp_title", "array", "List of data parameters for In-App title"),
  createData("inapp_body", "array", "List of data parameters for In-App body"),
  createData("inapp_image", "string", "Pass an image URL to display the image in In-App notificaiton"),
  createData("inapp_action1", "string", "Set the action title for In-App.e.g : Share"),
  createData("inapp_action2", "string", "Set the another action title for In-App. e.g: Participate"),
  createData("inapp_type", "string", "Pass the type of the notification.It helps to redirect the page. e.g : Teams "),
  createData("inapp_clientIds", "array of string", "List of In-App notification receivers's Id"),
  createData("inapp_eventReferenceId", "string", "Pass the reference eventId to In-App notificaiton. e.g: 11"),
  createData("inapp_icon", "string", "Pass an image URL to set as an icon to the In-App notification"),
  createData("is_send_push_notification", "boolean",
    "In default it's true.If it's false then does not send any notification"),
  createData("is_send_inapp_notification", "boolean", "in Default it's true.If it's false then does not send any notification"),
  createData("is_user_specific_notification", "boolean",
    `In default it's false. Set true - Send the Push and In-App notification for only one user.`),
  createData("to_mobiles", "array", "List of mobile numbers to send SMS"),
  createData("sms_body", "array", "List of data parameters for sms body"),
  createData(
    "URL",
    "string",
    "URL Shortener. Use {URL} de-limiter in the alert rule SMS body content"
  ),
  createData("to_emails", "array", "List of emails to send Mail"),
  createData("email_CC", "array", "List of data parameters for email CC"),
  createData("email_BCC", "array", "List of data parameters for email BCC"),
  createData("from_mail", "string", "Sender E-mail ID"),
  createData(
    "email_subject",
    "array",
    "List of data parameters for email subject"
  ),
  createData("email_body", "array", "List of data parameters for email body"),
  createData("email_attachments", "array", "List of attachments for email"),
];

// var answer4 = "{";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${200}px)`,
      marginLeft: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0px",
    },
    // height: `calc(100vh - ${60}px)`,
    height: "100%",
    backgroundColor: "white",
    overflow: "hidden  ",
  },
  grow: {
    flexGrow: 1,
  },
  content: {
    padding: "2%",
  },
  header: {
    fontSize: "1.1rem",
    fontWeight: "600",
    paddingBottom: "1.1%",
  },
  table: {
    padding: "1% 0%",
  },
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    padding: "2%",
  },
  query: {
    //margin: theme.spacing(1),
    //padding: "1% 0",
    width: "55%",
  },
  box: {
    display: "flex",
  },
  search: {
    marginLeft: "8px",
    border: "1px solid #9b4f4f",
    borderRadius: "2px",
    borderColor: "rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  bodyInput: {
    "& .MuiInputLabel-root": {
      textTransform: "capitalize",
    },
  },
  inputRoot: {
    height: "45px",
  },
  inputInput: {
    padding: theme.spacing(1),
    height: "20px",
    transition: theme.transitions.create("width"),
    fontSize: "16px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 248,
      "&:focus": {
        width: 244,
      },
    },
  },
  table2: {
    minWidth: 650,
  },
  tablecellHead: {
    fontWeight: 600,
    color: "#636563",
    padding: "8px",
  },
  textarea: {
    resize: "horizontal",
  },
  codeArea: {
    width: "600px",
    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
  },
}));

function ContentApi() {
  const classes = useStyles();

  const [apiBody, setApiBody] = React.useState({
    reference_id: "",
    alert_key: sessionStorage?.auth_token,
    push_receivers: [],
    push_title: [],
    push_body: [],
    push_data: {},
    push_click_action: "",
    push_icon: "",
    push_image: "",
    push_actions: [
      {
        title: "",
        action: ""
      }
    ],
    whatsapp_body: [],
    whatsapp_template_name: "",
    inapp_title: [],
    inapp_body: [],
    inapp_image: '',
    inapp_action1: "",
    inapp_action2: "",
    inapp_type: "",
    inapp_eventReferenceId: "",
    inapp_clientIds: [],
    inapp_icon: "",
    is_send_push_notification: true,
    is_send_inapp_notification: true,
    is_user_specific_notification: false,
    to_mobiles: [],
    sms_body: [],
    URL: "",
    to_emails: [],
    email_CC: [],
    email_BCC: [],
    from_mail: "",
    email_subject: [],
    email_body: [],
    email_attachments: [
      {
        content: "",
        filename: "",
        type: "",
        disposition: "",
      },
    ],
  });

  const [response, setResponse] = React.useState();

  // const tryApi = () => {
  //   console.log("apiBody", apiBody);
  //   axios
  //     .post(process.env.REACT_APP_LOCAL_ENDPOINT + "/sendmessage", apiBody)
  //     .then((res) => setResponse(res))
  //     .catch((err) => {
  //       setResponse({ message: err.message });
  //     });
  // };

  const tryApi = () => {
    console.log("apiBody", apiBody);
    NetworkCall("/sendmessage", "post", apiBody, null, true)
      .then((res) => setResponse(res))
      .catch((err) => {
        setResponse({ message: err.message });
      });
  };

  const handleChange = (event) => {
    var { name, value } = event.target;
    if (Array.isArray(apiBody[name])) {
      value = value.split(",");
    }
    if (name === "email_attachments") {
      value = JSON.parse(value);
    }
    setApiBody({
      ...apiBody,
      [name]: value,
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.table}>
        <Typography
          style={{
            fontWeight: 600,
            color: "#3DD7F0",
            paddingTop: ".8rem",
            paddingBottom: ".8rem",
            paddingLeft: "2rem",
          }}
        >
          TRY OUT
        </Typography>
        <Divider></Divider>
        <div style={{ padding: "2rem" }}>
          <Paper
            style={{ backgroundColor: "#ebeff3" }}
            className={classes.paper}
          >
            <div style={{ display: "flex" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="api-call">API calls</InputLabel>
                <Select
                  labelId="api-call"
                  id="api-call"
                  label="Select"
                  placeholder="Select"
                  labelWidth={40}
                  value={10}
                  disabled
                >
                  <MenuItem value={10}>Post</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id="outlined-full-width"
                disabled
                style={{ marginTop: 0, backgroundColor: "#FCFDFE" }}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={
                  process.env.REACT_APP_LOCAL_ENDPOINT + "/sendmessage"
                }
                variant="outlined"
              />
            </div>

            <div style={{ display: "flex", marginTop: "20px" }}>
              <div className={classes.codeArea}>
                <Typography style={{ fontWeight: "600", color: "#299265" }}>
                  Request Body
                </Typography>

                <div className={classes.codeArea}>
                  <pre
                    style={{
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                    }}
                  >
                    <code>{JSON.stringify(apiBody, null, 2)}</code>
                  </pre>
                </div>
              </div>

              <div style={{ flexGrow: 1 }}></div>
              <div>
                {Object.keys(apiBody).map((item, index) => (
                  <TextField
                    className={classes.bodyInput}
                    multiline
                    inputProps={{ className: classes.textarea }}
                    key={index}
                    style={{ margin: ".5%" }}
                    name={item}
                    label={item.replace(/_/g, " ")}
                    variant="outlined"
                    value={
                      item === "email_attachments"
                        ? JSON.stringify(apiBody[item])
                        : apiBody[item]
                    }
                    onChange={handleChange}
                    size="small"
                    fullWidth
                  />
                ))}
              </div>
            </div>

            <Button variant="contained" color="primary" onClick={tryApi}>
              Try
            </Button>

            {response && (
              <>
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#299265",
                    marginTop: 10,
                  }}
                >
                  Response
                </Typography>
                <pre
                  style={{
                    wordBreak: "break-all",
                  }}
                >
                  <code
                    style={{
                      color: response?.status === 200 ? green[500] : red[300],
                    }}
                  >
                    {JSON.stringify(response, null, 2)}
                  </code>
                </pre>
              </>
            )}
          </Paper>
        </div>
        <div style={{ padding: "0 25px" }}>
          <TableContainer>
            <Table className={classes.table2} size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    classes={{ head: classes.tablecellHead }}
                    align="left"
                  >
                    PARAMETER
                  </TableCell>
                  <TableCell
                    classes={{ head: classes.tablecellHead }}
                    align="left"
                  >
                    TYPE
                  </TableCell>
                  <TableCell
                    classes={{ head: classes.tablecellHead }}
                    align="left"
                  >
                    DESCRIPTION
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell
                      classes={{ body: classes.tablecellHead }}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.type}</TableCell>
                    <TableCell align="left">{row.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ marginTop: "55px" }}>
            <Typography
              style={{
                color: "#299265",
                fontWeight: "600",
                paddingBottom: "15px",
              }}
            >
              Sample Request
            </Typography>
            <Paper
              elevation={4}
              style={{
                backgroundColor: "#ebeff3",
                color: "#27A127",
                paddingTop: "10px",
                paddingBottom: "20px",
                paddingLeft: "0px",
              }}
            >
              <pre>
                <code>{`
    {
        "reference_id": "test",
        "alert_key": "eyJhbGciOiJIUzI1NiIsInsfFR5cdsdsdCI6IkpXVCJ9sds.eyJpZCI6ImIyMTU2ZWNmLWFiNTgtNGY0Zi1iYjlsSFDADFjLWJjYTk0Yzhm...",
        "push_receivers": ["fDh9oeyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImIyMTU2ZWNmLWFiNTgtNGY0Zi1iYjljLWJjYTk0YzhmM2U3OCI..."],
        "push_title": ["Test","Title"],
        "push_body": ["Test","Body"],
        "push_data": {
          "test": "push data"
        },
        "push_click_action": "https//www.example.com/path/",
        "push_icon": "https//www.example.com/logo.png",
        "to_mobiles": ["91XXXXXXXXXX"],
        "sms_body": ["Test","SMS","Body"],
        "URL":"http://alertshub.crayond.com",
        "to_emails": ["alertshub@crayond.com"],
        "email_CC": ["text_cc@alertshub.com","text_cc2@alertshub.com"],
        "email_BCC": ["text_bcc@alertshub.com","text_bcc2@alertshub.com"],
        "from_mail": "text_from_mail@alertshub.com",
        "email_subject": ["test","email","subject"],
        "email_body": ["test","email","body"],
        "email_attachments": [
          {
            "content": "R0lGODlhPQBEAPeoAJosM//AwO/AwHVYZ/z595kzAP/s7P+goOXMv8+fhw/v739/f+8PD98fH/8mJl+fn/9ZWb8u7zPzWlwPzWlwvvWlwv...",
            "filename": "test.jpeg",
            "type": "base64",
            "disposition": "attachment"
          }
        ]
    }`}</code>
              </pre>
            </Paper>
          </div>
          <div style={{ marginTop: "55px" }}>
            <Typography
              style={{
                color: "#299265",
                fontWeight: "600",
                paddingBottom: "15px",
              }}
            >
              Sample Response
            </Typography>
            <Paper
              elevation={4}
              style={{
                backgroundColor: "#ebeff3",
                color: "#27A127",
                paddingTop: "10px",
                paddingBottom: "20px",
              }}
            >
              <pre>
                {" "}
                <code>{`
    {
        "message": "Notification has sent through Push, EMAIL and SMS",
        "type": "Success",
        "push_status": {
            "message": "Push notification has been sent successfully",
            "type": "Success"
        },
        "mail_status": {
            "message": "Email has been sent successfully",
            "type": "Success"
        },
        "sms_status": {
            "message": "SMS has been sent successfully",
            "type": "Success"
        }
    }`}</code>
              </pre>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentApi;
