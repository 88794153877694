import React from "react";
import { Grid, Button, Typography, Link } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import { withRouter } from "react-router-dom";
import Logo1 from "../../Assets/favico.png";
import { AlertContext } from "../../Contexts";

import NetworkCall from "../../NetworkCall";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100vh",
  },
  child1st: {
    width: 420,
    margin: "auto",
    background: "white",
    padding: "20px 15px",
    borderRadius: "10px",
  },
  buttonStyleTop: {
    textAlign: "center",
    margin: "20px",
    padding: "10px",
  },
  buttonInside: {
    textTransform: "capitalize",
  },
  purple: {
    fontWeight: "800",
    fontSize: "10px",
  },
  fieldsBody: {
    background: "#f3f3f3",
    margin: "0px 15px 37px 15px",
    borderRadius: "10px",
    padding: "10px 15px 10px 15px",
  },
  TextfieldIndex: {
    fontSize: "0.65rem",
    color: "#636869",
  },
});

class Login extends React.Component {
  constructor(props) {
    super();
    this.state = {
      username: "",
      password: "",
      error: {
        username: false,
        password: false,
      },
    };
    // this.handleLogin= this.handleLogin.bind(this);
  }
  handleChanges(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleLogin = () => {
    let state = this.state;

    /* validate username */
    if (state.username.length <= 0) {
      state.error.username = true;
      this.setState({
        ...state,
      });
    }
    /* validate password */
    if (state.password.length <= 0) {
      state.error.password = true;
      this.setState({
        ...state,
      });
      return;
    }

    if (this.state.username.length > 0 && this.state.password.length > 0) {
      state.error.username = false;
      state.error.password = false;
      this.setState({
        ...state,
      });
      let payload = this.state;
      delete payload.error;
      NetworkCall("/users/login", "post", payload)
        .then((response) => {
          var { accessToken, profileId, username } = response.data;
          window.sessionStorage.setItem("username", username);
          window.sessionStorage.setItem("auth_token", accessToken);
          window.sessionStorage.setItem("profileId", profileId);
          this.props.history.push("/alertrule");
        })
        .catch((err) => {
          this.context.setSnack({
            open: true,
            severity: "error",
            msg: "Invalid Credentials!",
          });
        });
    }
  };
  handleSignupRedirect = () => {
    this.props.history.push("/signup");
  };
  render() {
    let { classes } = this.props;

    return (
      <Grid container className={classes.root}>
        <Grid
          container
          className={classes.child1st}
          style={{ boxShadow: "3px 4px 5px 5px #c1bfbf" }}
        >
          <Grid item md={12} style={{ margin: "35px 0px 12px 0px" }}>
            <Grid
              item
              md={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Avatar className={classes.purple} src={Logo1}>
                {" "}
              </Avatar>
            </Grid>
            <Grid
              item
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "10px 0px 20px 0px",
              }}
            >
              <Typography
                style={{
                  fontSize: "11px",
                  fontWeight: 800,
                  textTransform: "uppercase",
                }}
              >
                Login To Your Account
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.fieldsBody}>
            <Grid item md={12} style={{ marginTop: 10 }}>
              <Typography className={classes.TextfieldIndex}>
                USERNAME*
              </Typography>
              <TextField
                id="username"
                variant="outlined"
                fullWidth={true}
                value={this.state.username}
                required
                size="small"
                placeholder="username"
                name="username"
                onChange={(e) => this.handleChanges(e)}
                margin="dense"
                helperText={
                  this.state.error.username ? "please enter your username" : ""
                }
                error={this.state.error.username}
              />
            </Grid>
            <Grid item md={12}>
              <Typography className={classes.TextfieldIndex}>
                PASSWORD*
              </Typography>
              <TextField
                id="password"
                variant="outlined"
                fullWidth={true}
                value={this.state.password}
                required
                size="small"
                placeholder="password"
                name="password"
                onChange={(e) => this.handleChanges(e)}
                margin="dense"
                type="password"
                // FormHelperText={{error:this.state.error.password}}
                helperText={
                  this.state.error.password ? "please enter your password" : ""
                }
                error={this.state.error.password}
              />
            </Grid>
            <Grid item md={12} className={classes.buttonStyleTop}>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  this.handleLogin();
                }}
                className={classes.buttonInside}
                type="button"
                style={{ width: "100%" }}
              >
                Log In
              </Button>
              <Typography
                className={classes.TextfieldIndex}
                style={{ marginTop: "5%", fontSize: "0.80rem" }}
              >
                To create a new account{" "}
                <Link
                  style={{ fontSize: "0.85rem", cursor: "pointer" }}
                  onClick={this.handleSignupRedirect}
                >
                  sign up
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
Login.contextType = AlertContext;
export default withRouter(withStyles(styles, { withTheme: true })(Login));
