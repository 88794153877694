import React, { Component } from "react";
import ContentApi from "./content"
import Dashboard from "../Dashboard/index";

import "./apipage.css";

class Apipage extends Component {
  render() {
    return (
      <div>
        <Dashboard></Dashboard>
         <ContentApi></ContentApi>
      </div>
    );
  }
}

export default Apipage;
