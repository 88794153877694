import React from "react";
import { Grid, Button, Typography, Link } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import { withRouter } from "react-router-dom";
import Logo1 from "../../Assets/favico.png";

import NetworkCall from "../../NetworkCall";
import { AlertContext } from "../../Contexts";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100vh",
  },
  success: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
  },
  child1st: {
    width: 420,
    margin: "auto",
    background: "white",
    padding: "10px 15px",
    borderRadius: "10px",
  },
  buttonStyleTop: {
    textAlign: "center",
    margin: "6px 10px 6px 10px",
    padding: "10px",
  },
  buttonInside: {
    textTransform: "capitalize",
  },
  purple: {
    fontWeight: "800",
    fontSize: "10px",
  },
  fieldsBody: {
    background: "#f3f3f3",
    margin: "0px 15px 5px 15px",
    borderRadius: "10px",
    padding: "10px 15px 10px 15px",
  },
  TextfieldIndex: {
    fontSize: "0.65rem",
    color: "#636869",
  },
});

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      username: "",
      password: "",
      error: {
        username: false,
        password: false,
        firstName: false,
        lastName: false,
        email: false,
        mobile: false,
      },
      success: {},
    };
    // this.handleLogin= this.handleLogin.bind(this);
  }
  handleChanges(event) {
    let error = {};
    if (event.target.value.length > 0) {
      error[event.target.name] = false;
    }
    this.setState({
      ...this.state,
      error,
      [event.target.name]: event.target.value,
    });
  }

  handleLoginRedirect = () => {
    this.props.history.push("/login");
  };

  handleSignUp = () => {
    let state = this.state;
    /* validate all keys in state */
    let Keys = Object.keys(state);
    for (let index = 0; index < Keys.length; index++) {
      const element = Keys[index];
      if (state.error[element]) {
        return;
      }
      if (element !== "error") {
        if (state[element].length === 0) {
          state.error[element] = `${element} cannot be empty!`;
          this.setState({ ...state });
          return;
        }
      }
    }
    NetworkCall("/users/add", "post", { ...this.state })
      .then((response) => {
        this.context.setSnack({
          open: true,
          severity: "success",
          msg: response.data.message,
        });
      })
      .catch((err) => {
        this.context.setSnack({
          open: true,
          severity: "error",
          msg: "Some error occuered, Please try again!",
        });
      });
  };

  validate_username = () => {
    NetworkCall("/users/check_username", "post", {
      username: this.state.username,
    })
      .then((response) => {
        var { data } = response;
        let state = this.state;
        if (data.statusCode === 500) {
          state.error["username"] = data.message;
          this.setState({
            ...state,
          });
        } else if (data.statusCode === 200) {
          state["error"]["username"] = false;
          state["success"]["username"] = data.message;
          this.setState({ ...state });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    let { classes } = this.props;

    return (
      <Grid container className={classes.root}>
        <Grid
          container
          className={classes.child1st}
          style={{ boxShadow: "3px 4px 5px 5px #c1bfbf" }}
        >
          <Grid item md={12} style={{ margin: "8px 0px 4px 0px" }}>
            <Grid
              item
              md={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Avatar className={classes.purple} src={Logo1}>
                {" "}
              </Avatar>
            </Grid>
            <Grid
              item
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "10px 0px 20px 0px",
              }}
            >
              <Typography
                style={{
                  fontSize: "11px",
                  fontWeight: 800,
                  textTransform: "uppercase",
                }}
              >
                Sign Up to continue
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.fieldsBody}>
            <Grid item md={12} style={{ marginTop: 10 }}>
              <Typography className={classes.TextfieldIndex}>
                FIRST NAME*
              </Typography>
              <TextField
                id="firstName"
                variant="outlined"
                fullWidth={true}
                value={this.state.firstName}
                required
                size="small"
                placeholder="First name"
                name="firstName"
                onChange={(e) => this.handleChanges(e)}
                margin="dense"
                helperText={
                  this.state.error.firstName
                    ? "please enter your firstname"
                    : ""
                }
                error={this.state.error.firstName}
              />
            </Grid>

            <Grid item md={12}>
              <Typography className={classes.TextfieldIndex}>
                LAST NAME*
              </Typography>
              <TextField
                id="lastName"
                variant="outlined"
                fullWidth={true}
                value={this.state.lastName}
                required
                size="small"
                placeholder="Last Name"
                name="lastName"
                onChange={(e) => this.handleChanges(e)}
                margin="dense"
                helperText={
                  this.state.error.lastName ? "please enter your lastname" : ""
                }
                error={this.state.error.lastName}
              />
            </Grid>

            <Grid item md={12}>
              <Typography className={classes.TextfieldIndex}>
                EMAIL ID*
              </Typography>
              <TextField
                id="email"
                variant="outlined"
                fullWidth={true}
                value={this.state.email}
                required
                size="small"
                placeholder="Email"
                name="email"
                onChange={(e) => this.handleChanges(e)}
                type="email"
                margin="dense"
                helperText={
                  this.state.error.email ? "please enter your email" : ""
                }
                error={this.state.error.email}
              />
            </Grid>

            <Grid item md={12}>
              <Typography className={classes.TextfieldIndex}>
                MOBILE*
              </Typography>
              <TextField
                id="mobile"
                variant="outlined"
                fullWidth={true}
                value={this.state.mobile}
                required
                size="small"
                placeholder="Mobile"
                name="mobile"
                onChange={(e) => this.handleChanges(e)}
                type="number"
                margin="dense"
                helperText={
                  this.state.error.mobile
                    ? "please enter your mobile number"
                    : ""
                }
                error={this.state.error.mobile}
              />
            </Grid>
            <Grid item md={12}>
              <Typography className={classes.TextfieldIndex}>
                USERNAME*
              </Typography>
              <TextField
                id="username"
                variant="outlined"
                fullWidth={true}
                onBlur={this.validate_username}
                className={this.state?.success?.username ? classes.success : ""}
                value={this.state.username}
                required
                size="small"
                placeholder="Username"
                name="username"
                onError={this.validate_username}
                onChange={(e) => this.handleChanges(e)}
                margin="dense"
                // FormHelperText={{error:this.state.error.password}}
                helperText={
                  this.state.error.username
                    ? this.state.error.username
                    : this.state?.success?.username
                    ? this.state?.success?.username
                    : ""
                }
                error={this.state.error.username ? true : false}
              />
            </Grid>
            <Grid item md={12}>
              <Typography className={classes.TextfieldIndex}>
                PASSWORD*
              </Typography>
              <TextField
                id="password"
                variant="outlined"
                fullWidth={true}
                value={this.state.password}
                required
                size="small"
                placeholder="Password"
                name="password"
                onChange={(e) => this.handleChanges(e)}
                margin="dense"
                type="password"
                // FormHelperText={{error:this.state.error.password}}
                helperText={
                  this.state.error.password ? "please enter your password" : ""
                }
                error={this.state.error.password}
              />
            </Grid>
            <Grid item md={12} className={classes.buttonStyleTop}>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  this.handleSignUp();
                }}
                className={classes.buttonInside}
                type="button"
                style={{ width: "100%" }}
              >
                Signup
              </Button>
              <Typography
                className={classes.TextfieldIndex}
                style={{ marginTop: "5%", fontSize: "0.80rem" }}
              >
                If you've an account already{" "}
                <Link
                  style={{ fontSize: "0.85rem", cursor: "pointer" }}
                  onClick={this.handleLoginRedirect}
                >
                  log in
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
Signup.contextType = AlertContext;
export default withRouter(withStyles(styles, { withTheme: true })(Signup));
