import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

import {
  Toolbar,
  Grid,
  InputBase,
  Button,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "fixed",

    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${200}px)`,
      paddingLeft: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: "0px",
    },
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    border: "1px solid #9b4f4f",
    borderColor: "rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  searchIcon: {
    color: "#9b4f4f",
    width: theme.spacing(5),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 5),
    transition: theme.transitions.create("width"),
    fontSize: "14px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 248,
      "&:focus": {
        width: 244,
      },
    },
  },
  button: {
    margin: "5px",
    height: "31px",
    paddingTop: "4px",
    // backgroundColor: "#5ecdff",
    // '&:hover': {
    //     backgroundColor: "#45c5ff",
    // },
    float: "right",
  },
}));

export default function SearchAlertRuleTop(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Toolbar
        disableGutters
        style={{
          width: "100%",
          padding: "10px 12px 0 10px",
          backgroundColor: "#ebeff3",
        }}
      >
        <Grid className={classes.search}>
          <Grid className={classes.searchIcon}>
            <SearchIcon fontSize="small" />
          </Grid>
          <InputBase
            placeholder="Search your query"
            classes={{
              input: classes.inputInput,
              root: classes.inputRoot,
            }}
            className={classes.inputDiv}
            inputProps={{
              // autocomplete: "off",
              "aria-label": "Search",
            }}
            onChange={(e) => props.handleChangeSearch(e)}
            value={props.searchStr}
          />
        </Grid>

        <div className={classes.grow} />

        <Button
          style={{ textTransform: "none" }}
          onClick={(e) => props.handleOpenModel("Add")}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          <Typography
            variant={"body1"}
            style={{ fontWeight: 600, fontSize: "0.95rem" }}
          >
            Add Rule
          </Typography>
        </Button>
      </Toolbar>
    </div>
  );
}
