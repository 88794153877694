import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Grid } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";

import Logo1 from "../../Assets/favico.png";
import ProfileModal from "../Modals/profileModal";
import ConfigModal from "../Modals/configuration";
import { useHistory } from "react-router";

// import Dialog from '@material-ui/core/Dialog';
// import MsgcatalogEdit from '../Common/MsgcatalogEdit';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    // position:"fixed"
  },
  menuButton: {
    cursor: "pointer",
    fill: "#3f51b5",
  },
  title: {
    marginLeft: theme.spacing(2),
    cursor: "pointer",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  shadow: {
    boxShadow: "unset",
    position: "fixed",
    zindex: "10000",
  },
  list: {
    display: "inline-flex",
  },
  search: {
    position: "relative",
    border: "1px solid #9b4f4f",
    borderColor: "rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
    boxShadow: "0 0 3px 0px #00000013",
    marginRight: theme.spacing(1),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(4.2),
      width: 290,
      height: 24,
      paddingBottom: 6,
    },
  },
  searchIcon: {
    color: "#9b4f4f",
    width: theme.spacing(5),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 5),
    transition: theme.transitions.create("width"),
    fontSize: "14px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 248,
      "&:focus": {
        width: 244,
      },
    },
  },

  button: {
    margin: "5px",
    height: "31px",
    paddingTop: "4px",
    // backgroundColor: "#5ecdff",
    // '&:hover': {
    //     backgroundColor: "#45c5ff",
    // },
  },
  headerBar: {
    minHeight: "58px",
    backgroundColor: theme.palette.background.subheader,
    paddingLeft: "0",
    height: "58px",
    gridTemplateColumns: "315px 170px 1fr",
    paddingRight: "10px",
    // width: "100%"
  },
  [theme.breakpoints.only("xs")]: {
    search: {
      display: "none",
    },
    headerBar: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
  MenuBox: {
    boxShadow: "#3f51b5",
  },
  content: {
    alignItems: "center",
    textAlign: "right",
    display: "flex",
    paddingRight: "10px",
    color: "#000",
  },
  logo: {
    width: "50px",
    height: "50px ",
  },
  messagecatalog: {
    fontSize: "1.25rem",
    paddingTop: "4px",
    paddingLeft: "10px",
  },
  headLogoAlign: {
    display: "flex",
  },
}));

export default function SubHeaderHelp(props) {
  const classes = useStyles();
  let history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [profileClick, setProfileClick] = React.useState(false);
  const onclickProfile = () => {
    setProfileClick(true);
  };

  const profileClose = () => {
    setProfileClick(false);
  };

  const [configClick, setConfigClick] = React.useState(false);
  const onclickConfig = () => {
    setConfigClick(true);
  };

  const configClose = () => {
    setConfigClick(false);
  };

  const redirectToLogin = () => {
    window.sessionStorage.clear();
    history.push("/login");
  };

  return (
    <div>
      <Grid className={classes.root}>
        <AppBar position="static" className={classes.shadow}>
          <Toolbar className={classes.headerBar}>
            <Grid
              container
              style={{ width: "250px", padding: "0px 10px 0px 10px" }}
            >
              <div className={classes.headLogoAlign}>
                <Avatar
                  src={Logo1}
                  style={{ backgroundColor: "aliceblue" }}
                ></Avatar>
                <Typography className={classes.messagecatalog}>
                  Alerts Hub{" "}
                </Typography>
              </div>
            </Grid>

            <Grid
              container
              style={{
                justifyContent: "flex-end",
                marginRight: "10px",
                textAlign: "right",
                alignItem: "center",
                display: "flex",
                width: "100%",
              }}
            >
              <Avatar
                aria-controls="simple-menu"
                style={{
                  //padding: "1px",
                  cursor: "pointer",
                  //fontSize: "1.25em",
                }}
                onClick={handleClick}
              >
                {window.sessionStorage?.getItem("username")[0].toUpperCase() ??
                  ""}
              </Avatar>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{ top: "38px" }}
              >
                <MenuItem onClick={onclickProfile}>Profile</MenuItem>
                <MenuItem onClick={onclickConfig}>Configuration</MenuItem>
                <MenuItem onClick={redirectToLogin}>Logout</MenuItem>
              </Menu>
            </Grid>
          </Toolbar>
        </AppBar>
      </Grid>
      <ProfileModal
        openProfile={profileClick}
        handleProfileClose={profileClose}
      ></ProfileModal>
      <ConfigModal
        openConfig={configClick}
        handleConfigClose={configClose}
      ></ConfigModal>
    </div>
  );
}
