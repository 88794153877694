import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";
import "./menu.css";
import { withRouter } from "react-router-dom";
/* import Logo from "../../Assets/CrayondLogo.png";
import Logo1 from "../../Assets/favico.png";
import ListItemAvatar from "@material-ui/core/ListItemAvatar"; */
import clsx from "clsx";
/* import Avatar from "@material-ui/core/Avatar"; */

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    width: "100%",
    maxWidth: 360,
    backgroundColor: "secondary",
  },
  menuText: {
    margin: "6px 0px 4px 0px",
  },
  inline: {
    display: "inline",
    color: "#fff",
  },
  sidemenu: {
    background: theme.palette.background.menu,
    position: "fixed"
  },
  active: {
    "&.menu": {
      backgroundColor: "red",
    },
  },
  menu: {
    color: "red",
  },
  multilist: {
    padding: "4px 16px 4px 16px ",
  },
  avatar: {
    width: "130px",
    height: "85px",
    fontSize: "10px",
  },
}));

function NestedList(props) {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      style={{ width: "200px", }}
      className={classes.sidemenu}
    >
      <Grid
        item
        style={{ width: "200px", position: "fixed", height: "100%" }}
        className={classes.sidemenu}
      >
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          <ListItem alignItems="flex-start">
            {/* <ListItemAvatar style={{ marginTop: "4px", paddingBottom: "14px" }}> */}
            {/* <Avatar src={Logo1} className={classes.small} style={{backgroundColor:"aliceblue"}} /> */}
            {/* </ListItemAvatar> */}
          </ListItem>
          {/* <NavLink to={'/messagecatalog'} activeClassName={'active'} style={{ color: 'white', textDecoration: "none" }} >
                        <ListItem button className={clsx(classes.multilist, 'menu')}>
                            <ListItemText className={classes.menuText}
                                primary={
                                    <Typography variant="subtitle1" >
                                        Message List
                        </Typography>
                                }
                            />
                        </ListItem>
                    </NavLink> */}
          {/* <NavLink to={'/projects'} activeClassName={'active'} style={{ color: 'white', textDecoration: "none" }} > */}
          {/* <ListItem button className={clsx(classes.multilist, 'menu')}>
                        <ListItemText className={classes.menuText}
                            primary={
                                <Typography variant="subtitle1"  >
                                    Message Type
                                 </Typography>
                            }
                        />
                    </ListItem> */}
          {/* </NavLink> */}
          <NavLink
            to={"/alertrule"}
            activeClassName={"active"}
            style={{ color: "white", textDecoration: "none" }}
          >
            <ListItem button className={clsx(classes.multilist, "menu")}>
              <ListItemText
                className={classes.menuText}
                primary={
                  <Typography variant="subtitle1">Alert Rule</Typography>
                }
              />
            </ListItem>
          </NavLink>
          <NavLink
            to={"/apicalls"}
            activeClassName={"active"}
            style={{ color: "white", textDecoration: "none" }}
          >
            <ListItem button className={clsx(classes.multilist, "menu")}>
              <ListItemText
                className={classes.menuText}
                primary={
                  <Typography variant="subtitle1">Api Docs</Typography>
                }
              />
            </ListItem>
          </NavLink>
        </List>
      </Grid>
    </Grid>
  );
}
export default withRouter(NestedList);
