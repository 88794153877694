import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
const ColorCircularProgress = withStyles({
    root: {
        color: '#00695c',
    },
})(CircularProgress);
function FullLoader(props) {
    return (
        <div style={{ position: "absolute", zIndex: "9999", height: '85vh', width: '81%', display: "flex", left: props.left ? props.left : '', top: props.top ? props.top : '' }}>
            <ColorCircularProgress style={{ margin: "auto" }} size={30} thickness={5} />
        </div>
    )
}
export default FullLoader;