import React, { createRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Table, TextField } from '@material-ui/core'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Editicon from '../../Assets/EditIcon'
import { Grid, Typography } from '@material-ui/core'
import { Query } from 'react-apollo'
import { AllMESSAGECATALOG } from '../../Graphql/Queries'
import moment from 'moment'
import TablePagination from '@material-ui/core/TablePagination'
import Loader from '../Common/Loder/Fullloder'
import { capitalizeFirstLetter } from '../Common/Common'
import Popper from '@material-ui/core/Popper'
import { MenuList, MenuItem } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import SortDown from '../../Assets/sortDown'
import { MESSAGETYPE } from '../../Graphql/Queries'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    EditIcon: {
        width: 20,
        height: 20,
        fill: theme.palette.text.icon,


    },
    SortIcons: {
        height: 10,
        width: 10,
        fill: theme.palette.text.icon,

    },
    methodology: {
        padding: "10px 50px 10px 14px  !important"
    },
    textsort: {
        padding: "10px 22px 10px 14px  !important"
    },
    heading: {
        fontWeight: "bold",
        fontSize: "initial"
    },
    typoContent: {
        fontSize: "0.95rem"
    },
    typoContentRed: {
        fontSize: "0.95rem",
        color: "red"
    },
    typoContentGreen: {
        fontSize: "0.95rem",
        color: "green"
    },
    MenuBox: {
        boxShadow: " 0px 2px 1px -1px rgb(255, 255, 255), 0px 1px 1px 0px rgb(255, 255, 255), 0px 1px 3px 0px #9E9E9E"
    },
    TableHeader: {
        backgroundColor: theme.palette.background.tableHeader
    },
    TableBody: {
        backgroundColor: theme.palette.background.lightBackground
    },
    rowspage: {
        alignItems: "center",
        display: "flex",
        paddingLeft: "12px"
    },
    subheader1: {
        width: "100%",
        position: "fixed",
    },
    paperelevete1: {
        padding: "24px 16px 16px 16px"
    },
    paperelevete2: {
        padding: "64px 16px 16px 16px"
    }

})
const messageType = []
class MessageTable extends React.Component {
    textInput = createRef();
    constructor(props) {
        super(props)
        this.state = {
            category: 'True',
            rowperpage: 10,
            page: 0,
            totalmessage: 0,
            offset: 0,
            opendropdown: false,
            messageTypeId: ""
        }
    }
    componentDidMount() {
        //message type query
        this.props.query.query({
            query: MESSAGETYPE,

        })
            .then(res => {
                // eslint-disable-next-line
                res.data.allMessageTypes.nodes.map((val, index) => {
                    messageType.push(val)
                })
                this.setState({ msgTypeId: '' })
            })
            .catch(err => {
            })
    }

    handleClickOpen = () => {
        this.setState({ open: true })
    }
    handleChange = (event, name) => {
        this.setState({
            category: event.target.value
        })
    };
    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
            offset: newPage * this.state.rowperpage
        })
    };
    handleChangeRowsPerPage = event => {
        this.setState({
            rowperpage: parseInt(event.target.value, 10),
            page: 0,
            offset: 0,
        })
    };
    handleClose = (event) => {
        if (this.textInput.current && this.textInput.current.contains(event.target)) {
            return
        }
        this.setState({ opendropdown: false })
    }
    handleToggle = () => {
        this.setState({ opendropdown: !this.state.opendropdown })
    }
    handleClickValue = (value) => {
        this.setState({ messageTypeId: value })
    }

    focusTextInput = () => this.textInput.current.focus();

    render() {
        const { classes } = this.props
        return (
            <Grid>
                {this.state.rowperpage === 10 ? "" : <Grid item style={{ width: "100%", paddingBottom: "10px" }}>
                    <Paper className={classes.subheader1}>
                        {/* <Typography variant="subtitle1" >
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50]}
                                component="div"
                                count={this.state.totalmessage}
                                rowsPerPage={this.state.rowperpage}
                                page={this.state.page}
                                backIconButtonProps={{
                                    'aria-label': 'previous page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'next page',
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            /></Typography> */}
                        <Grid container >
                            <Grid item className={classes.rowspage}>
                                <Typography>
                                    Row per page:
                                </Typography>
                            </Grid>
                            <Grid item >
                                <TextField
                                    select
                                    // inputProps={{
                                    //     // maxlength: 15
                                    // }} // eslint-disable-next-line
                                    inputProps={{
                                        className: classes.textsort
                                    }}
                                    margin="dense"
                                    className={classes.margin}
                                    InputProps={{ disableUnderline: true }}
                                    name='MsgTypeId'
                                    value={this.state.rowperpage}
                                    onChange={this.handleChangeRowsPerPage}
                                >
                                    <MenuItem style={{ padding: 10, cursor: "pointer" }} key={1} value={10}>10</MenuItem>
                                    <MenuItem style={{ padding: 10, cursor: "pointer" }} key={2} value={20}>20</MenuItem>
                                    <MenuItem style={{ padding: 10, cursor: "pointer" }} key={3} value={50}>50</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>}
                <Grid item className={this.state.rowperpage === 10 ? classes.paperelevete1 : classes.paperelevete2}>
                    <Paper className={classes.root}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead className={classes.TableHeader} >
                                <TableRow>
                                    <TableCell>
                                        <Grid onClick={() => this.handleToggle()} ref={this.textInput}><Typography style={{ cursor: "pointer" }} className={classes.heading} variant="subtitle1">MessageType <SortDown className={classes.SortIcons} /> </Typography>
                                            <Popper open={this.state.opendropdown} keepMounted transition disablePortal style={{ right: '21px', height: "1px" }} >
                                                {({ TransitionProps, placement }) => (
                                                    <Grow
                                                        {...TransitionProps}
                                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                                    >
                                                        <Paper id="menu-list-grow" className={classes.MenuBox} style={{ overflow: "auto", maxHeight: 210, position: "absolute", zIndex: "1000", width: "14%" }}>
                                                            <ClickAwayListener onClickAway={e => this.handleClose(e)}>
                                                                <MenuList>
                                                                    <MenuItem onClick={() => this.handleClickValue("")} style={{ padding: 10, cursor: "pointer" }} >
                                                                        All
                                                                    </MenuItem>
                                                                    {messageType.map(option => (
                                                                        <MenuItem onClick={() => this.handleClickValue(option.messageTypeId)} style={{ padding: 10, cursor: "pointer" }} key={option.messageTypeId} value={option.messageTypeId}>
                                                                            {option.messageType}
                                                                        </MenuItem>
                                                                    ))}
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Grow>
                                                )}
                                            </Popper>
                                        </Grid></TableCell>
                                    <TableCell align="center"><Grid ><Typography className={classes.heading} variant="subtitle1">Msg Identifier</Typography></Grid>
                                    </TableCell>
                                    <TableCell align="center"><Typography className={classes.heading} variant="subtitle1">Msg Text</Typography></TableCell>
                                    <TableCell align="center"><Typography className={classes.heading} variant="subtitle1">Updated At</Typography></TableCell>
                                    <TableCell align="center"><Typography className={classes.heading} variant="subtitle1">Updated By</Typography></TableCell>
                                    <TableCell align="center"><Typography className={classes.heading} variant="subtitle1">IsActive</Typography></TableCell>
                                    <TableCell align="center"><Typography className={classes.heading} variant="subtitle1">Edit</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <Query
                                    network-only
                                    query={AllMESSAGECATALOG(this.state.messageTypeId)}
                                    onCompleted={data => this.setState({ totalmessage: data.allMessageCatalogs.totalCount })} variables={{ "Veiw": this.state.rowperpage, "Offset": this.state.offset, "searchStr": `%${this.props.searchStr}%`, "MessageTypeId": this.state.messageTypeId }}>
                                    {
                                        ({ data, loading, error, refetch }) => {
                                            if (loading) {
                                                return <Loader />
                                            }
                                            if (error) {
                                                return false
                                            }
                                            if (data) {
                                                return <>
                                                    {data.allMessageCatalogs.nodes.map((row, index) => (
                                                        <TableRow key={index} className={((index + 1) % 2) === 0 ? classes.TableBody : ""} >
                                                            <TableCell component="th" scope="row">
                                                                <Typography className={classes.typoContent}>{row && row.messageTypeByMessageTypeId && row.messageTypeByMessageTypeId.messageType}</Typography>
                                                            </TableCell>
                                                            <TableCell align="center"><Typography className={classes.typoContent}>{(row && row.messageIdentifier)}</Typography></TableCell>
                                                            <TableCell align="center"><Typography className={classes.typoContent}> {capitalizeFirstLetter(row && row.messageText)}</Typography> </TableCell>
                                                            <TableCell align="center"><Typography className={classes.typoContent}>{moment(row && row.updatedAt).format('MMM Do YYYY')}</Typography></TableCell>
                                                            <TableCell align="center"><Typography className={classes.typoContent}>{capitalizeFirstLetter(row && row.userProfileByCreatedBy && row.userProfileByCreatedBy.firstName)}</Typography></TableCell>
                                                            <TableCell align="center"><Typography className={classes.typoContent}>{row && row.isActive ? <Typography className={classes.typoContentGreen}>True</Typography> : <Typography className={classes.typoContentRed}>False</Typography>}</Typography></TableCell>
                                                            <TableCell align="center">
                                                                <div onClick={() => this.props.handleOpenModel("Edit", row && row.messageCatalogId)}>
                                                                    <Editicon className={classes.EditIcon} />
                                                                </div></TableCell>
                                                        </TableRow>
                                                    ))}
                                                </>
                                            }
                                        }}
                                </Query>

                            </TableBody>
                        </Table>
                        <Grid>
                            <Typography variant="subtitle1">
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, 50]}
                                    component="div"
                                    count={this.state.totalmessage}
                                    rowsPerPage={this.state.rowperpage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                /></Typography>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}
export default (withStyles(styles, { withTheme: true })((MessageTable)))