import gql from "graphql-tag";

// const ADDMESSAGECATALOG = gql`
//   mutation AddMessageCatalog (
//     $messageCatalogId:UUID!
//     $messageTypeId: UUID!
//     $messageIdentifier: Int!
//     $messageText: String!
//     $isActive: Boolean
//     $createdAt: Datetime!
//     $createdBy: UUID
//     $updatedBy: UUID
//     $updatedAt: Datetime!
//   ){
//     __typename
//     createMessageCatalog(input: {
//         messageCatalog: {
//             messageCatalogId:$messageCatalogId,
//             messageTypeId: $messageTypeId,
//             messageIdentifier: $messageIdentifier, 
//             messageText: $messageText, 
//             isActive: $isActive, 
//             createdAt: $createdAt, 
//             createdBy: $createdBy,
//             updatedBy: $updatedBy,
//             updatedAt: $updatedAt,
//         }
//     }) {
//       clientMutationId
//     }
//   }
//   `;
const ADDMESSAGECATALOG = gql`
  mutation AddMessageCatalog (
    $messageCatalogId:UUID!
    $messageTypeId: UUID!
    $messageIdentifier: Int!
    $messageText: String!
    $isActive: Boolean
    $createdAt: Datetime!
    $updatedAt: Datetime!
  ){
    __typename
    createMessageCatalog(input: {
        messageCatalog: {
            messageCatalogId:$messageCatalogId,
            messageTypeId: $messageTypeId,
            messageIdentifier: $messageIdentifier, 
            messageText: $messageText, 
            isActive: $isActive, 
            createdAt: $createdAt, 
            updatedAt: $updatedAt,
        }
    }) {
      clientMutationId
    }
  }
  `;
// const EDITCATALOG = gql`
//   mutation AddMessageCatalog (
//     $messageCatalogId:UUID!
//     $messageTypeId: UUID!
//     $messageIdentifier: Int!
//     $messageText: String!
//     $isActive: Boolean
//     $updatedBy: UUID
//     $updatedAt: Datetime!
//   ){
//     __typename
//     updateMessageCatalogByMessageCatalogId(input: {
//         messageCatalogPatch: {
//             isActive: $isActive, 
//             messageIdentifier: $messageIdentifier, 
//             messageText: $messageText, 
//             messageTypeId: $messageTypeId,
//             updatedBy: $updatedBy,
//             updatedAt: $updatedAt,
//         },
//             messageCatalogId:$messageCatalogId
//         }) {
//       clientMutationId
//     }
//   }
//   `;
const EDITCATALOG = gql`
  mutation AddMessageCatalog (
    $messageCatalogId:UUID!
    $messageTypeId: UUID!
    $messageIdentifier: Int!
    $messageText: String!
    $isActive: Boolean
    $updatedAt: Datetime!
  ){
    __typename
    updateMessageCatalogByMessageCatalogId(input: {
        messageCatalogPatch: {
            isActive: $isActive, 
            messageIdentifier: $messageIdentifier, 
            messageText: $messageText, 
            messageTypeId: $messageTypeId,
            updatedAt: $updatedAt,
        },
            messageCatalogId:$messageCatalogId
        }) {
      clientMutationId
    }
  }
  `;
  const ADDALERTRULE = gql`
  mutation AddAlertRule(
    $alertRuleId: UUID!
    $alertRuleCode: String!
    $createdAt: Datetime!
    $updatedAt: Datetime!
    $description: String
    $emailBody: String
    $emailSubject: String
    $isActive: Boolean
    $isEmail: Boolean
    $isSms: Boolean
    $isPush: Boolean
    $pushBody: String
    $pushTitle: String
    $smsBody: String
  ) {
    __typename
    createAlertRule(
      input: {
        alertRule: {
          alertRuleId: $alertRuleId
          alertRuleCode: $alertRuleCode
          createdAt: $createdAt
          updatedAt: $updatedAt
          description:  $description
          emailBody: $emailBody
          emailSubject: $emailSubject
          isActive: $isActive
          isEmail: $isEmail
          isPush: $isPush
          isSms: $isSms
          pushBody: $pushBody
          pushTitle: $pushTitle
          smsBody: $smsBody
        }
      }
    ) {
      alertRule {
        alertRuleId
      }
    }
  }
  
  `;
  const EDITALERTRULE = gql`
  mutation editAlertRule(
    $alertRuleId: UUID!
    $alertRuleCode: String!
    $updatedAt: Datetime!
    $description: String
    $emailBody: String
    $emailSubject: String
    $isActive: Boolean
    $isEmail: Boolean
    $isSms: Boolean
    $isPush: Boolean
    $pushBody: String
    $pushTitle: String
    $smsBody: String
  ) {
    __typename
    updateAlertRuleByAlertRuleId(
      input: {
        alertRulePatch: {
          alertRuleCode: $alertRuleCode
          updatedAt: $updatedAt
          description: $description
          emailBody: $emailBody
          emailSubject: $emailSubject
          isActive: $isActive
          isEmail: $isEmail
          isPush: $isPush
          isSms: $isSms
          pushBody: $pushBody
          pushTitle: $pushTitle
          smsBody: $smsBody
        }
        alertRuleId: $alertRuleId
      }
    ) {
      alertRule {
        alertRuleId
      }
    }
  }  
  `;

export {
    ADDMESSAGECATALOG,
    EDITCATALOG,
    ADDALERTRULE,
    EDITALERTRULE
}