import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, MenuItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { EDITLOG, MESSAGETYPE, MESSAGEIDENTIFIER } from '../../../Graphql/Queries'
import { ADDMESSAGECATALOG, EDITCATALOG, } from '../../../Graphql/Mutation'
import uuidv1 from 'uuid/v1';
import TostMessage from '../ToastMessage'


const styles = theme => ({
    button: {
        padding: '5px 10px',
        textTransform: "capitalize",
        fontWeight: "500"
    },
    butt: {
        justifyContent: "flex-end",
    },
    text: {
        paddingLeft: '8px',
    },
    icons: {
    },
    margin: {
        width: "100%",
        marginTop: "8px",
        marginBottom: 10,
    },
    testbig: {
        paddingLeft: 15,
        paddingRight: 15,
    },
    Textfield2: {
        width: "100%",
        marginBottom: 8,

    },
    text1: {
        fontWeight: "bold",
        paddingLeft: "7px",
        fontSize: "1.0rem"
    },
    methodology: {
        fontSize: "0.95rem"
    }

});
const isActive = [
    {
        value: true,
        label: "True",
    },
    {
        value: false,
        label: "False",
    },

];
const messageType = [];
const messageIdentify = [];
class MsgcatalogEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: true,
            msgTypeId: "",
            msgIdentify: "",
            msgText: '',
            error: false,
            errorMsgTypeId: '',
            errorMshIdentify: '',
            messageTpeId: "",
            errorMsgtext: "",
            openToast: false
        }
    }
    componentDidMount() {
        //message type query
        this.props.query.query({
            query: MESSAGETYPE,

        })
            .then(res => {
                // eslint-disable-next-line
                res.data.allMessageTypes.nodes.map((val, index) => {
                    messageType.push(val)
                })
                this.setState({ msgTypeId: '' })
            })
            .catch(err => {
            })
        //message identifier unique to find query
        this.props.query.query({
            query: MESSAGEIDENTIFIER,

        })
            .then(res => {
                // eslint-disable-next-line
                res.data.allMessageCatalogs.nodes.map((val) => {
                    messageIdentify.push(val.messageIdentifier)
                })

            })
            .catch(err => {
            })
        //view on ediing message query
        if (this.props.callFrom === "Edit") {
            this.props.query.query({
                query: EDITLOG,
                variables: {
                    "Id": this.props.catalogId,
                }
            })
                .then(res => {
                    let msgTypeId = res.data.allMessageCatalogs.nodes[0].messageTypeId
                    let msgIdentify = res.data.allMessageCatalogs.nodes[0].messageIdentifier
                    let msgText = res.data.allMessageCatalogs.nodes[0].messageText
                    let isActive = res.data.allMessageCatalogs.nodes[0].isActive
                    this.setState({ msgTypeId, msgIdentify, msgText, isActive })
                })
                .catch(err => {
                })
        }
    }

    componentWillUnmount() {
        messageType.length = 0;
    }

    handleChange = (event) => {
        this.setState({
            isActive: event.target.value
        });
    };
    handletextchange = name => event => {
        this.setState({
            [name]: event.target.value,
            errorMshIdentify: "",
            errorMsgtext: "",
            errorMsgTypeId: ""
        });
    };
    handleunique = () => {
        // eslint-disable-next-line
        messageIdentify.map(val => {
            if (parseInt(this.state.msgIdentify) === val) {
                this.setState({ errorMshIdentify: "Message Identifier is already exists" })
            }
        })
    }
    submitvendor = async (e) => {
        await this.props.query.mutate({
            mutation: this.props.callFrom === "New" ? ADDMESSAGECATALOG : EDITCATALOG,
            variables: {
                "messageCatalogId": this.props.callFrom === "New" ? uuidv1() : this.props.catalogId,
                "messageTypeId": this.state.msgTypeId,
                "messageIdentifier": parseInt(this.state.msgIdentify),
                "messageText": this.state.msgText, 
                "isActive": this.state.isActive,
                "createdAt": new Date(),
                // "createdBy": "fd872c77-acae-4756-9f4a-a526e8ea374e",
                // "updatedBy": "fd872c77-acae-4756-9f4a-a526e8ea374e",
                "updatedAt": new Date(),
            }

        })
            .then(res => {
                console.log(res)
                this.setState({ openToast: true })
                this.props.handleCloseModel();
                debugger;
            })
            .catch(err => {
                console.log(err);
                
            })

    }
    handleError = (e, name) => {
        if (!this.state.errorMsgTypeId) {
            this.setState({ errorMsgTypeId: "Message Identifier is already exists" })
        }
        if (name === 1) {
            this.setState({ errorMshIdentify: "Message Identifier is already exists" })
        }
        if (name === 2) {
            this.setState({ errorMsgtext: "Message Identifier is already exists" })
        }

    }
    render() {
        const { classes } = this.props

        return (
            <Grid className={classes.root}>
                {/* <form onSubmit={e => this.submitvendor(e)}> */}
                    <Grid container style={{ padding: "10px 15px" }}>
                        <Grid item xs={6} sm={6} lg={6} xl={6}>
                            <Grid container style={{ paddingTop: "6px" }}>
                                <Grid>
                                    <Typography color={"textPrimary"} className={classes.text1}>
                                        Add MessageCatalog
                                 </Typography> 
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6} lg={6} xl={6} >
                            <Grid container className={classes.butt}>
                                <Button onClick={(e) => this.props.handleCloseModel(e)} className={classes.button} style={{ marginRight: "7px" }}><Typography className={classes.cancelBtn} style={{ fontSize: "0.9rem" }}>Cancel</Typography></Button>
                                <Button variant="contained" type='submit' color="primary" className={classes.button} onClick={(e)=>this.submitvendor(e)} >
                                    <Typography>Submit</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{ paddingBottom: "15px" }}>
                        <Divider style={{ width: "100%", marginBottom: '10px', backgroundColor: "rgba(0, 0, 0, 0.07)" }} />

                        <Grid container className={classes.testbig}>

                            <TextField
                                select
                                // inputProps={{
                                //     // maxlength: 15
                                // }} // eslint-disable-next-line
                                inputProps={{
                                    className: classes.methodology
                                }}
                                margin="dense"
                                className={classes.margin}
                                label="Message TypeId"
                                variant="outlined"
                                required
                                name='MsgTypeId'
                                value={this.state.msgTypeId}
                                onChange={this.handletextchange("msgTypeId")}
                                error={this.state.errorMsgTypeId ? true : false}
                                helperText={this.state.errorMsgTypeId ? this.state.errorMsgTypeId : ""}

                            >{messageType.map(option => (
                                <MenuItem style={{ padding: 10, cursor: "pointer" }} key={option.messageTypeId} value={option.messageTypeId}>
                                    {option.messageType}
                                </MenuItem>
                            ))}
                            </TextField>
                            <Grid container>
                                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <TextField
                                        margin="dense"
                                        inputProps={{
                                            className: classes.methodology
                                        }}
                                        className={classes.margin}
                                        label="Identifier"
                                        variant="outlined"
                                        required
                                        onBlur={e => this.handleunique(e)}
                                        name='MsgIdentify'
                                        type="number"
                                        onChange={this.handletextchange("msgIdentify")}
                                        value={this.state.msgIdentify}
                                        onInvalid={e => this.handleError(e, 1)}
                                        error={this.state.errorMshIdentify ? true : false}
                                        helperText={this.state.errorMshIdentify ? this.state.errorMshIdentify : ""}

                                    />
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ paddingLeft: "10px" }}>
                                    <TextField
                                        select
                                        fullWidth
                                        margin="dense"
                                        label="IsActive"
                                        name="IsActive"
                                        required

                                        style={{ fontWeight: 500 }}
                                        variant="outlined"
                                        inputProps={{
                                            input: classes.Status,
                                            className: classes.methodology
                                        }}
                                        value={this.state.isActive}
                                        onChange={(e) => this.handleChange(e, 'isActive')}
                                    >
                                        {isActive.map(option => (
                                            <MenuItem style={{ padding: 10, cursor: "pointer" }} key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>

                            <TextField

                                margin="dense"
                                className={classes.margin}
                                label="Message Text"
                                variant="outlined"
                                required
                                inputProps={{
                                    className: classes.methodology
                                }}
                                onInvalid={e => this.handleError(e, 2)}
                                onChange={this.handletextchange("msgText")}
                                value={this.state.msgText}
                                name='msgText'
                                rows={6}
                                multiline={true}
                                error={this.state.errorMsgtext ? true : false}
                                helperText={this.state.errorMsgtext ? this.state.errorMsgtext : ""}
                            />
                            <TostMessage openToast={this.state.openToast} />
                        </Grid>
                    </Grid>
                {/* </form> */}
            </Grid>
        );
    }
}
export default (withStyles(styles, { withTheme: true })((MsgcatalogEdit)))
