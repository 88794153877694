import React from "react";
import "./App.css";
import RouterApp from "./Routers/Index";
import ApolloGQLclient from "./ApolloGQLclient";
import AppTheme from "./AppThemes";
import AppToast from "./App.toast";

function App() {
  return (
    <ApolloGQLclient>
      <AppTheme>
        <AppToast>
          <div className="App">
            <RouterApp />
          </div>
        </AppToast>
      </AppTheme>
    </ApolloGQLclient>
  );
}

export default App;
